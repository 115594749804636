<template>
  <div>
    <div>
      <div style="margin-bottom: 24px">
        <a-card :bordered="false">
          <DescriptionList :title="this.newOrEdit()" size="large">
            <a-card :bordered="false" style="margin-left: -16px">
              <div class="table-operator">
                <a-form layout="inline" :form="form">
                  <a-row
                    :gutter="5"
                    style="line-height: 4"
                    class="stallsEditLabelWidth"
                  >
                    <a-col :md="8" :sm="24">
                      <a-form-item label="推送任务名称">
                        <a-input
                          style="width: 240px"
                          v-decorator="[
                            'taskName',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ],
                              initialValue: this.showMessage(detail.taskName)
                            }
                          ]"
                          placeholder="请输入"
                        />
                      </a-form-item>
                    </a-col>
                    <a-col :md="8" :sm="24">
                      <a-form-item label="推送客户端">
                        <a-select
                          style="width: 240px"
                          v-decorator="[
                            'clientType',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请选择！'
                                }
                              ],
                              initialValue: this.showMessage(detail.clientType)
                            }
                          ]"
                          placeholder="请选择"
                        >
                          <a-select-option
                            v-for="item in pushClient"
                            :key="item.id"
                          >
                            {{ item.name }}
                          </a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-col>
                    <a-col :md="24" :sm="24">
                      <a-form-item label="推送对象">
                        <a-radio-group
                          @change="onChange"
                          v-decorator="[
                            'customerType',
                            {
                              rules: [
                                {
                                  required: true
                                }
                              ],
                              initialValue: detail.customerType || 1
                            }
                          ]"
                          name="radioGroup"
                        >
                          <a-radio
                            v-for="item in radioGroupList"
                            :value="item.id"
                            v-bind:key="item.id"
                            >{{ item.name }}</a-radio
                          >
                          <!--                          <a-radio :value="2">指定用户</a-radio>-->
                        </a-radio-group>
                      </a-form-item>
                    </a-col>
                    <div v-if="radioGroupStatus == 1">
                      <a-col :md="16" :sm="24" class="stallsEditLabelWidth">
                        <a-form-item label="选择城市">
                          <a-select
                            style="min-width: 607px"
                            mode="multiple"
                            v-decorator="[
                              'pushMessageDTO.cityIdList',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: '请选择！'
                                  }
                                ],
                                initialValue: this.showMessage(
                                  detail.cityIdList
                                )
                              }
                            ]"
                            placeholder="请选择"
                          >
                            <a-select-option
                              v-for="item in userCities"
                              :key="item.id"
                            >
                              {{ item.name }}
                            </a-select-option>
                          </a-select>
                        </a-form-item>
                      </a-col>
                      <a-col :md="16" :sm="24">
                        <a-form-item label="消息标题">
                          <a-input
                            style="width: 607px"
                            v-decorator="[
                              'pushMessageDTO.title',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: '请输入！'
                                  }
                                ],
                                initialValue: detail.title
                              }
                            ]"
                            placeholder="请输入"
                          />
                        </a-form-item>
                      </a-col>
                      <a-col :md="16" :sm="24">
                        <a-form-item label="消息内容">
                          <a-input
                            style="width: 607px"
                            v-decorator="[
                              'pushMessageDTO.content',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: '请输入！'
                                  }
                                ],
                                initialValue: detail.content
                              }
                            ]"
                            placeholder="请输入"
                          />
                        </a-form-item>
                      </a-col>
                      <a-col :md="16" :sm="24">
                        <a-form-item label="链接">
                          <a-input
                            style="width: 607px"
                            v-decorator="[
                              'pushMessageDTO.link',
                              {
                                rules: [
                                  {
                                    required: false,
                                    message: '请输入！'
                                  }
                                ],
                                initialValue: detail.link
                              }
                            ]"
                            placeholder="请输入"
                          />
                        </a-form-item>
                      </a-col>
                    </div>
                    <div v-if="radioGroupStatus == 2">
                      <a-col :md="24" :sm="24">
                        <a-form-item label="上传推送信息">
                          <a-upload
                            name="multipartFile"
                            withCredentials
                            accept=".doc,.docx,.xlsx"
                            :action="this.uploadUrl()"
                            :fileList="fileList"
                            @change="handleChange"
                          >
                            <a-button>
                              <a-icon type="upload" /> 选择文件
                            </a-button>
                          </a-upload>
                        </a-form-item>
                      </a-col>
                      <a-col :md="24" :sm="24">
                        <Divider />
                        <a-form-item label="推送方式">
                          <a-radio-group
                            @change="onChangeStyle"
                            v-decorator="[
                              'pushType',
                              {
                                rules: [
                                  {
                                    required: true
                                  }
                                ],
                                initialValue: detail.pushType
                              }
                            ]"
                            name="radioGroup"
                          >
                            <a-radio
                              v-for="item in radioGroupStyle"
                              :value="item.id"
                              v-bind:key="item.name"
                              >{{ item.name }}</a-radio
                            >
                          </a-radio-group>
                        </a-form-item>
                      </a-col>
                      <a-col v-if="radioGroupStyleStatus == 2" :md="8" :sm="24">
                        <a-form-item label="推送时间">
                          <a-time-picker
                            style="width: 240px"
                            v-decorator="[
                              'pushTime',
                              {
                                rules: [
                                  {
                                    required: true,
                                    message: '请输入！'
                                  }
                                ],
                                initialValue: moment(detail.pushTimeLg)
                              }
                            ]"
                          />
                        </a-form-item>
                      </a-col>
                    </div>
                  </a-row>
                </a-form>
              </div>
            </a-card>
          </DescriptionList>
        </a-card>
      </div>
      <div style="margin-top: 8px">
        <a-card :bordered="false">
          <div class="steps-action">
            <a-button @click="cancel">
              取消
            </a-button>
            <a-button
              v-show="
                this.thisDetailstatus == '待推送' ||
                  this.thisDetailId == undefined
              "
              type="primary"
              style="margin-left: 8px"
              @click="conserve"
            >
              保存
            </a-button>
          </div>
        </a-card>
      </div>
    </div>
  </div>
</template>

<script>
import { Divider } from "ant-design-vue";
import DescriptionList from "@/components/DescriptionList";
import { modal } from "ant-design-vue";
  
import moment from "moment";
import Bus from "@/bus";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import { GET_USER_CITIES } from "@/store/modules/actions.type";
export default {
  name: "operatePushNew",
  components: {
    DescriptionList,
    Divider
  },
  computed: {
    ...mapGetters("cities", ["userCities"])
  },

  data() {
    return {
      thisDetailId: this.$route.query.thisDetailId,
      thisDetailstatus: this.$route.query.thisDetailstatus,
      thisDetailcustomerType: this.$route.query.thisDetailcustomerType,
      detail: {
        taskName: undefined
      },
      confirmLoading: false,
      form: this.$form.createForm(this),
      queryParam: {},
      advanced: false,
      radioGroupStatus: this.$route.query.thisDetailcustomerType || 1,
      // pushType: this.detail.pushType,
      radioGroupStyleStatus: "",
      radioGroupList: [
        {
          id: 1,
          name: "全部"
        },
        {
          id: 2,
          name: "指定用户"
        }
      ],
      radioGroupStyle: [
        {
          id: 1,
          name: "立即推送"
        },
        {
          id: 2,
          name: "定时推送"
        }
      ],
      //推送客户端
      pushClient: [
        {
          id: 1,
          name: "客户端"
        },
        {
          id: 2,
          name: "运营端"
        },
        {
          id: 3,
          name: "全部"
        }
      ],
      fileList: [],
      messageFileUrl: ""
    };
  },
  created() {
    this.init();
    this.getUserCities();
    Bus.$on("getTarget", target => {
      this.$router.push({
        path: "/operate/operatePush"
      });
    });
  },
  methods: {
    ...mapActions("cities", [GET_USER_CITIES]),
    moment,
    init() {
      if (this.thisDetailId) {
        this.configQuery();
      }
    },
    uploadUrl() {
      return   this.$baseUrl + "upload/ossFile";
    },
    configQuery() {
      axios({
        url:   this.$baseUrl + "/push/config/query",
        method: "GET",
        params: {
          id: this.thisDetailId
        }
      }).then(res => {
        this.detail = res.data.obj;
      });
    },
    cancel() {
      this.$router.push({ path: "/operate/operatePush" });
    },
    conserve() {
      let that = this;
      const {
        form: { validateFields }
      } = this;
      this.confirmLoading = true;
      validateFields((errors, values) => {
        if (!errors) {
          modal.confirm({
            title: "是否需要保存?",
            onOk() {
              that.thisDetailId == null
                ? that.configSave(values)
                : that.configModify(values);
              that.$router.push("/operate/operatePush");
              return new Promise((resolve, reject) => {
                setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
              }).catch(() => console.log("Oops errors!"));
            },
            onCancel() {}
          });
        }
      });
    },
    configSave(values) {
      axios({
        url:   this.$baseUrl + "/push/config/save",
        method: "POST",
        data: {
          ...values,
          messageFileUrl: this.messageFileUrl
        }
      }).then(res => {
        // alert(res);
        // this.data = res.data.rows;
      });
    },
    configModify(values) {
      axios({
        url:   this.$baseUrl + "/push/config/modify",
        method: "POST",
        data: {
          ...values,
          id: this.thisDetailId,
          messageFileUrl: this.messageFileUrl
        }
      }).then(res => {
        // alert(res);
        // this.data = res.data.rows;
      });
    },
    newOrEdit() {
      if (!this.thisDetailId) {
        return "新建任务配置";
      } else {
        return "编辑任务配置";
      }
    },
    onChange(e) {
      this.radioGroupStatus = e.target.value;
    },
    onChangeStyle(e) {
      this.radioGroupStyleStatus = e.target.value;
      console.log("radio checked", this.radioGroupStyleStatus);
    },
    handleChange(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-2);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.url;
          this.messageFileUrl = file.response.obj;
          console.log(file.response.obj);
        }
        return file;
      });
      this.fileList = fileList;
    },
    showMessage(val) {
      return val ? val : undefined;
    }
  }
};
</script>

<style scoped></style>
