var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('div',{staticStyle:{"margin-bottom":"24px"}},[_c('a-card',{attrs:{"bordered":false}},[_c('DescriptionList',{attrs:{"title":this.newOrEdit(),"size":"large"}},[_c('a-card',{staticStyle:{"margin-left":"-16px"},attrs:{"bordered":false}},[_c('div',{staticClass:"table-operator"},[_c('a-form',{attrs:{"layout":"inline","form":_vm.form}},[_c('a-row',{staticClass:"stallsEditLabelWidth",staticStyle:{"line-height":"4"},attrs:{"gutter":5}},[_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"推送任务名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'taskName',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请输入！'
                              }
                            ],
                            initialValue: this.showMessage(_vm.detail.taskName)
                          }
                        ]),expression:"[\n                          'taskName',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请输入！'\n                              }\n                            ],\n                            initialValue: this.showMessage(detail.taskName)\n                          }\n                        ]"}],staticStyle:{"width":"240px"},attrs:{"placeholder":"请输入"}})],1)],1),_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"推送客户端"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'clientType',
                          {
                            rules: [
                              {
                                required: true,
                                message: '请选择！'
                              }
                            ],
                            initialValue: this.showMessage(_vm.detail.clientType)
                          }
                        ]),expression:"[\n                          'clientType',\n                          {\n                            rules: [\n                              {\n                                required: true,\n                                message: '请选择！'\n                              }\n                            ],\n                            initialValue: this.showMessage(detail.clientType)\n                          }\n                        ]"}],staticStyle:{"width":"240px"},attrs:{"placeholder":"请选择"}},_vm._l((_vm.pushClient),function(item){return _c('a-select-option',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"推送对象"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                          'customerType',
                          {
                            rules: [
                              {
                                required: true
                              }
                            ],
                            initialValue: _vm.detail.customerType || 1
                          }
                        ]),expression:"[\n                          'customerType',\n                          {\n                            rules: [\n                              {\n                                required: true\n                              }\n                            ],\n                            initialValue: detail.customerType || 1\n                          }\n                        ]"}],attrs:{"name":"radioGroup"},on:{"change":_vm.onChange}},_vm._l((_vm.radioGroupList),function(item){return _c('a-radio',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),(_vm.radioGroupStatus == 1)?_c('div',[_c('a-col',{staticClass:"stallsEditLabelWidth",attrs:{"md":16,"sm":24}},[_c('a-form-item',{attrs:{"label":"选择城市"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'pushMessageDTO.cityIdList',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请选择！'
                                }
                              ],
                              initialValue: this.showMessage(
                                _vm.detail.cityIdList
                              )
                            }
                          ]),expression:"[\n                            'pushMessageDTO.cityIdList',\n                            {\n                              rules: [\n                                {\n                                  required: true,\n                                  message: '请选择！'\n                                }\n                              ],\n                              initialValue: this.showMessage(\n                                detail.cityIdList\n                              )\n                            }\n                          ]"}],staticStyle:{"min-width":"607px"},attrs:{"mode":"multiple","placeholder":"请选择"}},_vm._l((_vm.userCities),function(item){return _c('a-select-option',{key:item.id},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)],1),_c('a-col',{attrs:{"md":16,"sm":24}},[_c('a-form-item',{attrs:{"label":"消息标题"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'pushMessageDTO.title',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ],
                              initialValue: _vm.detail.title
                            }
                          ]),expression:"[\n                            'pushMessageDTO.title',\n                            {\n                              rules: [\n                                {\n                                  required: true,\n                                  message: '请输入！'\n                                }\n                              ],\n                              initialValue: detail.title\n                            }\n                          ]"}],staticStyle:{"width":"607px"},attrs:{"placeholder":"请输入"}})],1)],1),_c('a-col',{attrs:{"md":16,"sm":24}},[_c('a-form-item',{attrs:{"label":"消息内容"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'pushMessageDTO.content',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ],
                              initialValue: _vm.detail.content
                            }
                          ]),expression:"[\n                            'pushMessageDTO.content',\n                            {\n                              rules: [\n                                {\n                                  required: true,\n                                  message: '请输入！'\n                                }\n                              ],\n                              initialValue: detail.content\n                            }\n                          ]"}],staticStyle:{"width":"607px"},attrs:{"placeholder":"请输入"}})],1)],1),_c('a-col',{attrs:{"md":16,"sm":24}},[_c('a-form-item',{attrs:{"label":"链接"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'pushMessageDTO.link',
                            {
                              rules: [
                                {
                                  required: false,
                                  message: '请输入！'
                                }
                              ],
                              initialValue: _vm.detail.link
                            }
                          ]),expression:"[\n                            'pushMessageDTO.link',\n                            {\n                              rules: [\n                                {\n                                  required: false,\n                                  message: '请输入！'\n                                }\n                              ],\n                              initialValue: detail.link\n                            }\n                          ]"}],staticStyle:{"width":"607px"},attrs:{"placeholder":"请输入"}})],1)],1)],1):_vm._e(),(_vm.radioGroupStatus == 2)?_c('div',[_c('a-col',{attrs:{"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"上传推送信息"}},[_c('a-upload',{attrs:{"name":"multipartFile","withCredentials":"","accept":".doc,.docx,.xlsx","action":this.uploadUrl(),"fileList":_vm.fileList},on:{"change":_vm.handleChange}},[_c('a-button',[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" 选择文件 ")],1)],1)],1)],1),_c('a-col',{attrs:{"md":24,"sm":24}},[_c('Divider'),_c('a-form-item',{attrs:{"label":"推送方式"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'pushType',
                            {
                              rules: [
                                {
                                  required: true
                                }
                              ],
                              initialValue: _vm.detail.pushType
                            }
                          ]),expression:"[\n                            'pushType',\n                            {\n                              rules: [\n                                {\n                                  required: true\n                                }\n                              ],\n                              initialValue: detail.pushType\n                            }\n                          ]"}],attrs:{"name":"radioGroup"},on:{"change":_vm.onChangeStyle}},_vm._l((_vm.radioGroupStyle),function(item){return _c('a-radio',{key:item.name,attrs:{"value":item.id}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),(_vm.radioGroupStyleStatus == 2)?_c('a-col',{attrs:{"md":8,"sm":24}},[_c('a-form-item',{attrs:{"label":"推送时间"}},[_c('a-time-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'pushTime',
                            {
                              rules: [
                                {
                                  required: true,
                                  message: '请输入！'
                                }
                              ],
                              initialValue: _vm.moment(_vm.detail.pushTimeLg)
                            }
                          ]),expression:"[\n                            'pushTime',\n                            {\n                              rules: [\n                                {\n                                  required: true,\n                                  message: '请输入！'\n                                }\n                              ],\n                              initialValue: moment(detail.pushTimeLg)\n                            }\n                          ]"}],staticStyle:{"width":"240px"}})],1)],1):_vm._e()],1):_vm._e()],1)],1)],1)])],1)],1)],1),_c('div',{staticStyle:{"margin-top":"8px"}},[_c('a-card',{attrs:{"bordered":false}},[_c('div',{staticClass:"steps-action"},[_c('a-button',{on:{"click":_vm.cancel}},[_vm._v(" 取消 ")]),_c('a-button',{directives:[{name:"show",rawName:"v-show",value:(
              this.thisDetailstatus == '待推送' ||
                this.thisDetailId == undefined
            ),expression:"\n              this.thisDetailstatus == '待推送' ||\n                this.thisDetailId == undefined\n            "}],staticStyle:{"margin-left":"8px"},attrs:{"type":"primary"},on:{"click":_vm.conserve}},[_vm._v(" 保存 ")])],1)])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }